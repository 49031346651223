import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import { Link } from 'react-router-dom'
const ServiceInclude = ({ data, color, title, gridSize }) => {
  return (
    <div className={styles.wrapper}>
      <h3>{title ? title : `Our Fiction Writing Services Include`}</h3>
      <Container>
        <ul className={gridSize ? styles.ebookGrid : ''}>
          {data.map((item, key) => {
            return (
              <li key={key}>
                  <img src={item.imgSrc} alt="" />
                  <h4>{item.title}</h4>
              </li>
            )
          })}
        </ul>
      </Container>
    </div>
  )
}
export default ServiceInclude
