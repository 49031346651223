import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import { Link } from 'react-router-dom'
const Newsletter = ({ lightColor }) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <div
          className={styles.card}
          style={lightColor && { background: lightColor }}
        >
          <div className={styles.left}>
            <div className={styles.title}>
              For all your Ghostwriting
              <br />
              requirements
            </div>
            <Link to={'/contact-us'}>
              <button>
                Contact us now
                <img src="/images/right-arrow-white.svg" alt="" />
              </button>
            </Link>
          </div>
          <div className={styles.right}>
            <img src="/images/news_b_1.png" alt="" />
            <img src="/images/news_b_2.png" alt="" />
            <img src="/images/news_b_3.png" alt="" />
            <img src="/images/news_b_4.png" alt="" />
            <img src="/images/news_b_5.png" alt="" />
            <img src="/images/news_b_6.png" alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}
export default Newsletter
