export const PageDataEbook = {
  lightColor: '#FFF2CC',
  darkColor: '#DAA505',
  offerListHeading: 'All your books needs we cover',
  offerList: [
    {
      link: '/service/non-fictional',
      imgSrc: '/images/ebook_offer_1.svg',
      title: 'Non-Fictional books',
      description: 'Turn your expertise into a compelling non-fiction e-book.',
    },
    {
        link: '/service/autobiography-ghostwriting-services',
      imgSrc: '/images/ebook_offer_2.svg',
      title: 'Memoir/Autobiography',
      description: 'Immortalize your unique journey with a captivating memoir.',
    },
    {
      link: "/service/fictional",
      imgSrc: '/images/ebook_offer_3.svg',
      title: 'Fictional books',
      description:
        'Immerse readers in captivating worlds with our fiction ghostwriting.',
    },
    {
      link:"/service/childrens-literature-ghostwriting-services",
      imgSrc: '/images/ebook_offer_4.svg',
      title: 'Children’s Books',
      description: 'Spark imagination in young minds with enchanting tales',
    },
    {
      link:"/service/book-proposal-ghostwriting-services",
      imgSrc: '/images/ebook_offer_5.svg',
      title: 'Book Proposals',
      description: 'Pitch your book idea with confidence',
    },
    {
      link:"/service/academic-ghostwriting-services",
      imgSrc: '/images/ebook_offer_6.svg',
      title: 'Academic Books',
      description:
        'Share your research with the world through a polished, ghostwritten academic piece.',
    },
  ],
  includeTitle: 'Why E-books?',
  includeList: [
    {
      imgSrc: '/images/ebook_include_1.svg',
      title: 'Searchability',
    },
    {
      imgSrc: '/images/ebook_include_2.svg',
      title: 'Accessibility',
    },
    {
      imgSrc: '/images/ebook_include_3.svg',
      title: 'Cost-effective',
    },
    {
      imgSrc: '/images/ebook_include_4.svg',
      title: 'Portability',
    },
    {
      imgSrc: '/images/ebook_include_5.svg',
      title: 'Ease of Distribution',
    },
    {
      imgSrc: '/images/ebook_include_6.svg',
      title: 'Customization',
    },
    {
      imgSrc: '/images/ebook_include_7.svg',
      title: 'Interactivity',
    },
    {
      imgSrc: '/images/ebook_include_8.svg',
      title: 'Environment Friendly',
    },
  ],
  articleTitle: 'Some of our work for reference',
}
