import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import Faqs from '@/Components/Service/Faqs'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { PageDataFictional } from '@/Const/fictional'
import { Helmet } from 'react-helmet'
const ClientData = [
  {
    text: '“Estorytellers are well known for Ghostwriting. I thought to give them a shot. Overall, worth the hype. Keep it up! - Michael Schäffer ”',
    color: '#FFF4F4',
  },
  {
    text: '“The best Ghostwriting services in India. I called them to write a book for me. They not only supported me for writing, but also helped me with publishing. I am really satisfied with their services! - Uma Swaminathan\n”',
    color: '#FFE6E6',
  },
  {
    text: "“Remarkable ghostwriters at affordable rates, without worrying about any kind of problems with delivery and quality. That's what I would say about my experience with Estorytellers. - Xi Mang Po”",
    color: '#FFDADA',
  },
  {
    text: '“I like the honesty of Kritika Kanodia, CEO of Estorytellers. She also explained the whole process with ease. Project is currently, ongoing, and as of now, I am truly satisfied with their writers, delivery, and customer service. - Sakina Nazir\n”',
    color: '#FFCECE',
  },
  {
    text: "“They helped me realize my idea into a beautifully-curated book. I guess, if I hadn't approached you, I wouldn't ever be able to do it myself. Thank you so much! - Harpreet Kaur”",
    color: '#FFCECE',
  },
]
const ServiceNonFictionalPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Exclusive Non Fiction Ghostwriting Services | Estorytellers
        </title>
        <meta
          name="description"
          content="Explore our professional non fiction ghostwriting services to bring your book ideas to life. Experienced ghostwriters for your non fiction project."
        />
      </Helmet>
      <Header />
      <FictionalHero
        lightColor={'#FFEEEF'}
        darkColor={'#FF3E4F'}
        active={2}
        heading={'Turn your knowledge into a bestseller'}
        subheading={
          "You've got the ideas, we've got  skill.  Together, we'll create a book that informs, inspires, and sounds like you, even if you haven't written a word yourself."
        }
      />
      <ServiceOffer
        data={PageDataNonFictional.offerList}
        color={PageDataNonFictional.lightColor}
        title={PageDataNonFictional.offerListHeading}
      />
      <ServiceInclude
        data={PageDataNonFictional.includeList}
        color={PageDataNonFictional.lightColor}
        title={PageDataNonFictional.includeTitle}
      />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataNonFictional.darkColor}*/}
      {/*  lightColor={PageDataNonFictional.lightColor}*/}
      {/*/>*/}
      <ClientsHome
        bgColor={'#fff'}
        lightColor={PageDataNonFictional.lightColor}
        Data={ClientData}
        cardColor={PageDataNonFictional.lightColor}
      />
      <ServiceForm
        darkColor={PageDataNonFictional.darkColor}
        lightColor={PageDataNonFictional.lightColor}
      />
      <Faqs
        darkColor={PageDataNonFictional.darkColor}
        lightColor={PageDataNonFictional.lightColor}
      />
      <Newsletter lightColor={PageDataNonFictional.lightColor} />

      <Footer />
    </>
  )
}
export default ServiceNonFictionalPage
