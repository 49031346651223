import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className={styles.wrapper}>
      {/*  Address */}
      <div className={styles.area}>
        <div className={styles.address}>
          <div className={styles.logName}>Estorytellers</div>
          <p className={styles.addr}>
            91springboard, Lotus, Andheri East, Plot No. D-5 Road No. 20, Marol
            MIDC, Andheri East, Mumbai, Maharashtra. 400069
          </p>

          <div className={styles.services}>
            <div className={styles.title}>Quick links</div>
            <ul>
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>
                <Link to={'/blog/'}>Blog</Link>
              </li>
              {/*<li>*/}
              {/*  /!*<Link to={'/'}>About Us</Link>*!/*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <Link to={'/study-abroad-counselling'}>*/}
              {/*    Study Abroad Counselling*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link to={'/service/fictional'}>Services</Link>
              </li>
              <li>
                <Link to={'/contact-us'}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*    Services*/}
      <div className={styles.area}>
        <div className={styles.services}>
          <div className={styles.title}>Services</div>
          <ul>
            <li>
              <Link to={'/service/fictional'}>Fictional Writing</Link>
            </li>
            <li>
              <Link to={'/service/non-fictional'}>Non-Fictional Writing</Link>
            </li>
            <li>
              <Link to={'/service/ebook'}>E-book Writing</Link>
            </li>
          </ul>
        </div>
      </div>

      {/*    Quick Links*/}
      <div className={styles.area}>
        <div className={`${styles.services} ${styles.genre}`}>
          <div className={styles.title}>Genre</div>
          <ul>
            <li>
              <Link to={'/service/romance-novel-ghostwriter/'}>
                Romance Writing
              </Link>
            </li>
            <li>
              <Link to={'/service/childrens-literature-ghostwriting-services'}>
                Children’s Literature
              </Link>
            </li>
            <li>
              <Link to={'/service/health-ghostwriting-services'}>
                Health and Wellness{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/drama-ghostwriting-services'}>
                {' '}
                Drama/Suspense writing
              </Link>
            </li>
            <li>
              <Link to={'/service/autobiography-ghostwriting-services'}>
                {' '}
                Memoir/Autobiography
              </Link>
            </li>
            <li>
              <Link to={'/service/travel-ghostwriting-services'}>
                Travel and Culture{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/sci-fi-ghostwriting-services'}>
                Science Fiction Writing{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/biography-ghostwriting-services'}>
                Biography Writing{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/spirituality-ghostwriting-services'}>
                {' '}
                Spirituality Writing
              </Link>
            </li>
            <li>
              <Link to={'/service/supernatural-horror-ghostwriting-services'}>
                {' '}
                Supernatural/Horror Writing
              </Link>
            </li>
            <li>
              <Link to={'/service/psychology-ghostwriting-services'}>
                {' '}
                Self help/Psychology
              </Link>
            </li>
            <li>
              <Link to={'/service/comedy-ghostwriting-services'}>
                Comedy Writing{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/mystery-thriller-ghostwriting-services'}>
                Mystery Thriller Writing{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/academic-ghostwriting-services'}>
                Academic Book{' '}
              </Link>
            </li>
            <li>
              <Link to={'/service/finance-ghostwriting-services'}>
                {' '}
                Finance/Economy
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/*reviews*/}
      <div className={styles.area}>
        <div className={styles.rating}>
          <div className={styles.star}>
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
            <img src="/images/star.svg" alt="" />
          </div>
          <div>
            <span>5.0</span>/1,230 reviews
          </div>
        </div>
      </div>
      {/*    Social */}
      <div className={styles.area}>
        <div className={styles.copyRight}>
          <p>©2024 Estorytellers</p>
        </div>
      </div>
      {/*    Copywrite*/}
      <div className={styles.area}>
        <div className={styles.copyRight}>
          Registered Under Tutor Scholastic Learners Pvt. Ltd.
        </div>
      </div>
    </div>
  )
}
export default Footer
