import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import { PageDataFictional } from '@/Const/fictional'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import Faqs from '@/Components/Service/Faqs'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { Helmet } from 'react-helmet';
const ClientData = [
  {
      text: '“Estorytellers are well known for Ghostwriting. I thought to give them a shot. Overall, worth the hype. Keep it up! - Michael Schäffer ”',
    color: '#F4F6FF',
  },
  {
      text: '“The best Ghostwriting services in India. I called them to write a book for me. They not only supported me for writing, but also helped me with publishing. I am really satisfied with their services! - Uma Swaminathan\n”',
    color: '#E6EAFF',
  },
  {
      text: "“Remarkable ghostwriters at affordable rates, without worrying about any kind of problems with delivery and quality. That's what I would say about my experience with Estorytellers. - Xi Mang Po”",
    color: '#DAE0FF',
  },
  {
      text: '“I like the honesty of Kritika Kanodia, CEO of Estorytellers. She also explained the whole process with ease. Project is currently, ongoing, and as of now, I am truly satisfied with their writers, delivery, and customer service. - Sakina Nazir\n”',
    color: '#CED6FF',
  }, {
        text: "“They helped me realize my idea into a beautifully-curated book. I guess, if I hadn't approached you, I wouldn't ever be able to do it myself. Thank you so much! - Harpreet Kaur”",
    color: '#CED6FF',
  },
]
const ServiceFictionalPage = () => {
  return (
    <>
      <Helmet>
        <title>Best Fiction Ghostwriting Services | Estorytellers</title>
        <meta
          name="description"
          content="Professional fiction ghostwriting services to bring your stories to life. Our experienced ghostwriters create compelling novels and more. Contact us today!"
        />
      </Helmet>
      <Header />
      <FictionalHero active={1} darkColor={PageDataFictional.darkColor} />
      <ServiceOffer
        data={PageDataFictional.offerList}
        color={PageDataFictional.lightColor}
      />
      <ServiceInclude
        data={PageDataFictional.includeList}
        color={PageDataFictional.lightColor}
      />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataFictional.darkColor}*/}
      {/*  lightColor={PageDataFictional.lightColor}*/}
      {/*/>*/}
      <ClientsHome
        lightColor={PageDataNonFictional.lightColor}
        Data={ClientData}
        bgColor={'#fff'}
        cardColor={PageDataFictional.lightColor}
      />
      <ServiceForm
        darkColor={PageDataFictional.darkColor}
        lightColor={PageDataFictional.lightColor}
      />
      <Faqs
        darkColor={PageDataFictional.darkColor}
        lightColor={PageDataFictional.lightColor}
      />
      <Newsletter />

      <Footer />
    </>
  )
}
export default ServiceFictionalPage
