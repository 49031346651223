import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from '@/Pages/Home'
import Header from '@/Components/Header'
import ServiceFictionalPage from '@/Pages/Service/Fictional'
import ServiceNonFictionalPage from '@/Pages/Service/NonFictional'
import ServiceEbookPage from '@/Pages/Service/Ebook'
import PrivacyPolicy from '@/Pages/PrivacyPolicy'
import ServicePolicies from '@/Pages/ServicePolicies'
import StudyAbroadCounselling from '@/Pages/StudyAbroadCounselling'
import ContactUs from '@/Pages/ContactUs'
import Services from '@/Pages/Services'
import Romance from '@/Pages/Genre/Romance'
import Biography from '@/Pages/Genre/Biography'
import Business from '@/Pages/Genre/Business'
import ChildrenLiterature from '@/Pages/Genre/ChildrenLiterature'
import Children from '@/Pages/Genre/ChildrenLiterature'
import Comedy from '@/Pages/Genre/Comedy'
import Drama from '@/Pages/Genre/Drama'
import Finance from '@/Pages/Genre/Finance'
import HealthandWellness from '@/Pages/Genre/HealthandWellness'
import Autobiography from '@/Pages/Genre/Autobiography'
import Mystery from '@/Pages/Genre/MysteryThriller'
import ScienceFiction from '@/Pages/Genre/ScienceFiction'
import SelfHelp from '@/Pages/Genre/SelfHelp'
import Spirituality from '@/Pages/Genre/Spirituality'
import Horror from '@/Pages/Genre/Horror'
import TravelandCulture from '@/Pages/Genre/TravelandCulture'
import BookProposal from '@/Pages/Genre/Book Proposal'
import AcademicBook from '@/Pages/Genre/Academic Book'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route
          exact
          path="/service/fictional"
          element={<ServiceFictionalPage />}
        ></Route>
        <Route
          exact
          path="/service/non-fictional"
          element={<ServiceNonFictionalPage />}
        ></Route>{' '}
        <Route
          exact
          path="/service/ebook"
          element={<ServiceEbookPage />}
        ></Route>
        <Route exact path="/service/:slug" element={<Services />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route
          exact
          path="/service-policies"
          element={<ServicePolicies />}
        ></Route>{' '}
        <Route
          exact
          path="/study-abroad-counselling"
          element={<StudyAbroadCounselling />}
        ></Route>{' '}
        <Route exact path="/contact-us" element={<ContactUs />}></Route>
        <Route
          exact
          path="/service/romance-novel-ghostwriter/"
          element={<Romance />}
        ></Route>
        <Route
          exact
          path="/service/biography-ghostwriting-services"
          element={<Biography />}
        ></Route>
        <Route
          exact
          path="/service/thought-leadership-ghostwriting-services"
          element={<Business />}
        ></Route>{' '}
        <Route
          exact
          path="/service/childrens-literature-ghostwriting-services"
          element={<Children />}
        ></Route>
        <Route
          exact
          path="/service/comedy-ghostwriting-services"
          element={<Comedy />}
        ></Route>
        <Route
          exact
          path="/service/drama-ghostwriting-services"
          element={<Drama />}
        ></Route>
        <Route
          exact
          path="/service/finance-ghostwriting-services"
          element={<Finance />}
        ></Route>
        <Route
          exact
          path="/service/health-ghostwriting-services"
          element={<HealthandWellness />}
        ></Route>
        <Route
          exact
          path="/service/autobiography-ghostwriting-services"
          element={<Autobiography />}
        ></Route>
        <Route
          exact
          path="/service/mystery-thriller-ghostwriting-services"
          element={<Mystery />}
        ></Route>
        <Route
          exact
          path="/service/sci-fi-ghostwriting-services"
          element={<ScienceFiction />}
        ></Route>
        <Route
          exact
          path="/service/psychology-ghostwriting-services"
          element={<SelfHelp />}
        ></Route>{' '}
        <Route
          exact
          path="/service/spirituality-ghostwriting-services"
          element={<Spirituality />}
        ></Route>
        <Route
          exact
          path="/service/supernatural-horror-ghostwriting-services"
          element={<Horror />}
        ></Route>
        <Route
          exact
          path="/service/travel-ghostwriting-services"
          element={<TravelandCulture />}
        ></Route>{' '}
        <Route
          exact
          path="/service/book-proposal-ghostwriting-services"
          element={<BookProposal />}
        ></Route>
        <Route
          exact
          path="/service/academic-ghostwriting-services"
          element={<AcademicBook />}
        ></Route>
      </Routes>
    </Router>
  )
}
export default App
