export const PageDataNonFictional = {
  lightColor: '#FFEEEF',
  darkColor: '#EFA8AC',
  offerListHeading: 'Any genre you want, we offer.',
  offerList: [
    {
      link: '/service/autobiography-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_1.svg',
      title: 'Memoir/Autobiography',
      description: 'Share your remarkable journney with the world',
    },
    {
      link: '/service/biography-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_2.svg',
      title: 'Biography',
      description: 'We ensure your legacy lives on through our words.',
    },
    {
      link: '/service/psychology-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_3.svg',
      title: 'Self-Help/Psychology',
      description: 'Your expertise + our writers = an impactfull book',
    },
    {
      link: '/service/thought-leadership-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_4.svg',
      title: 'Business/Leadership/HR',
      description:
        'Use our words to convery your knowledge to a sea of eager readers.',
    },
    {
      link: '/service/health-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_5.svg',
      title: 'Health and Wellness',
      description:
        'Your proficiency in the healthcare industry deserves to be shared.',
    },
    {
      link: '/service/travel-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_6.svg',
      title: 'Travel and Culture',
      description:
        'Take your readers on a journey they’ll never forget with the help of our experts.',
    },
    {
      link: '/service/spirituality-ghostwriting-services',
      imgSrc: '/images/nonfictional_offer_7.svg',
      title: 'Spirituality',
      description: 'Share your wisdom. Write a book that uplifts.',
    },
    {
      link:"/service/comedy-ghostwriting-services",
      imgSrc: '/images/nonfictional_offer_8.svg',
      title: 'Comedy',
      description: 'You can craft the jokes, we can write your next bestseller',
    },
    {
      link:"/service/finance-ghostwriting-services",
      imgSrc: '/images/nonfictional_offer_9.svg',
      title: 'Finance/Economics',
      description:
        "Don't let complex finance concepts scare away readers. We'll write your book that simplifies and sells. ",
    },
  ],
  includeTitle: 'Our Non-fiction Writing Services Include',
  includeList: [
    {
      imgSrc: '/images/nonfictional_include_1.svg',
      title: 'Book Planning',
    },
    {
      imgSrc: '/images/nonfictional_include_2.svg',
      title: 'Ghostwriting',
    },
    {
      imgSrc: '/images/nonfictional_include_3.svg',
      title: 'Editing',
    },
    {
      imgSrc: '/images/nonfictional_include_4.svg',
      title: 'Proposals',
    },
    {
      imgSrc: '/images/nonfictional_include_5.svg',
      title: 'Publishing',
    },
    {
      imgSrc: '/images/nonfictional_include_6.svg',
      title: 'Targeting',
    },
    {
      imgSrc: '/images/nonfictional_include_7.svg',
      title: 'Testing',
    },
    {
      imgSrc: '/images/nonfictional_include_8.svg',
      title: 'Coaching',
    },
    {
      imgSrc: '/images/nonfictional_include_9.svg',
      title: 'Strategy',
    },
    {
      imgSrc: '/images/nonfictional_include_10.svg',
      title: 'Introduction',
    },
    {
      imgSrc: '/images/nonfictional_include_11.svg',
      title: 'Submission',
    },
    {
      imgSrc: '/images/nonfictional_include_12.svg',
      title: 'Editing',
    },
  ],
  articleTitle: 'Some of our work for reference',
}
