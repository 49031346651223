import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import styles from '@/Styles/content.module.scss'
import Container from '@/Components/Shared/Container'
import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import { Link } from 'react-router-dom'
import Newsletter from '@/Components/Home/Newsletter'
import { Helmet } from 'react-helmet'

const Services = () => {
  const [pageContent, setPageContent] = useState('')
  const { slug } = useParams() // Assuming `slug` is the variable part of your path defined in your route

  useEffect(() => {
    const fetchPageContent = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://estorytellers.com/blog/wp-json/wp/v2/pages?slug=${slug}/`,
        headers: {},
      }

      try {
        const response = await axios.request(config)
        console.log(">.......",response)
        if (response.data.length > 0) {
          setPageContent(response.data[0])
        } else {
          setPageContent('Page not found')
        }
      } catch (error) {
        console.log(error)
        setPageContent('Error fetching page content')
      }
    }

    fetchPageContent()
  }, [slug]) // Re-run the effect if `slug` changes

  if (
    pageContent === 'Page not found' ||
    pageContent === 'Error fetching page content'
  ) {
    return (
      <>
        <Header />
        <Container>
          <h1>Page not found</h1>
        </Container>
        <Footer />
      </>
    )
  }
  if(pageContent=== ''){
    return (
      <div className={styles.loader}></div>
    )
  }

  // Extract the title, description, and Open Graph data for better control
  const seoTitle = pageContent?.yoast_head_json?.title || 'Default Title'
  const seoDescription =
    pageContent?.yoast_head_json?.description || 'Default Description'
  const ogTitle = pageContent?.yoast_head_json?.og_title || seoTitle
  const ogDescription =
    pageContent?.yoast_head_json?.og_description || seoDescription
  const ogUrl = pageContent?.yoast_head_json?.og_url || window.location.href
  const ogImage = pageContent?.yoast_head_json?.og_image?.[0]?.url || ''
  const ogImageWidth = pageContent?.yoast_head_json?.og_image?.[0]?.width || ''
  const ogImageHeight =
    pageContent?.yoast_head_json?.og_image?.[0]?.height || ''
  return (
    <>

      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="article" />
        {ogImage && <meta property="og:image" content={ogImage} />}
        {ogImageWidth && (
          <meta property="og:image:width" content={ogImageWidth} />
        )}
        {ogImageHeight && (
          <meta property="og:image:height" content={ogImageHeight} />
        )}
        {/* Additional OG tags and other SEO-related tags can be added here */}
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.hero}>
          <Container>
            <div className={styles.breadcrumb}>
              <Link to="/">Home</Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
              >
                <path
                  d="M0.833008 11.4447L6.16634 6.11141L0.833008 0.778076"
                  stroke="#606060"
                  strokeWidth="1.06667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{pageContent?.title?.rendered}</span>
            </div>
            <h1>{pageContent?.title?.rendered}</h1>
          </Container>
        </div>
        <Container>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: pageContent?.content?.rendered }}
          />
        </Container>
      </div>
      <Newsletter />
      <Footer />
    </>
  )
}

export default Services
