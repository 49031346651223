import React, { useRef } from 'react'
import styles from './styles.module.scss'
import Container from '@/Components/Shared/Container'
import SecondaryButton from '@/Components/Shared/Buttons/Secondary'
import CardStackSlider from '@/Components/Shared/CardStackSlider'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'
import { Link } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia()

const ClientsHome = ({ bgColor, viewPointHeight, Data, cardColor }) => {
  const wrapperRef = useRef()
  const leftRef = useRef()
  const rightRef = useRef()

  useGSAP(() => {
    mm.add('(min-width: 800px)', () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: 'top +=100', // when the top of the trigger hits the top of the viewport
          end: '+=1000 0%', // end after scrolling 500px beyond the start
          scrub: true,
          pinSpacing: true, // Adjust pin spacing
        },
      })

      t1.fromTo(
        leftRef.current,
        {
          x: '-300%',
          scale: 0.9,
          ease: 'power1.easeInOut',
        },
        {
          x: 0,
          scale: 1,
          ease: 'power1.easeInOut',
        },
      )
        .fromTo(
          rightRef.current,
          {
            x: '300%',
            scale: 0.9,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            scale: 1,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          leftRef.current,
          {
            x: 0,
            scale: 1,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            x: 100,
            scale: 0.9,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          rightRef.current,
          {
            x: 0,
            scale: 1,
            opacity: 1,
            ease: 'power1.easeInOut',
          },
          {
            x: -100,
            scale: 0.9,
            opacity: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
    })
  })

  const combinedStyle = {
    ...(bgColor && { background: bgColor }), // Apply background color if provided
    ...(viewPointHeight && { minHeight: '100vh' }), // Apply view height if provided
  }
  return (
    <div className={styles.wrapper} style={combinedStyle} ref={wrapperRef}>
      <Container>
        <div className={styles.container}>
          <div className={styles.content} ref={leftRef}>
            <h5>Stories of success</h5>
            <p>
              Here’s how we helped other authors like you share their story with
              the world.
            </p>
            <Link to={'/contact-us'}>
              <SecondaryButton>Connect with our team</SecondaryButton>
            </Link>
            <div className={styles.reviewList}>
              <div className={styles.card}>
                <img src="/images/google.svg" alt="" />
                <div className={styles.text}>
                  Google Reviews
                  <div className={styles.rating}>
                    4.5
                    <img src="/images/star.svg" alt="" />
                  </div>
                </div>
              </div>

              <div className={styles.card}>
                <img src="/images/clutch.svg" alt="" />
                <div className={styles.text}>
                  Clutch Reviews
                  <div className={styles.rating}>
                    4.5
                    <img src="/images/star.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.sliderContainer} ref={rightRef}>
            <CardStackSlider Data={Data} cardColor={cardColor} />
          </div>
        </div>
      </Container>
    </div>
  )
}
export default ClientsHome
