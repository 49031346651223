import Header from '@/Components/Header'
import Footer from '@/Components/Footer'
import ServiceOffer from '@/Components/Service/Offer'
import ServiceInclude from '@/Components/Service/Inculde'
import ServiceWhatWeDo from '@/Components/Service/WhatWeDo'
import ServiceForm from '@/Components/Service/Form'
import Newsletter from '@/Components/Home/Newsletter'
import Faqs from '@/Components/Service/Faqs'
import FictionalHero from '@/Components/Service/FictionalHero'
import ClientsHome from '@/Components/Home/Clients'
import { PageDataEbook } from '@/Const/ebook'
import EbookHero from '@/Components/Service/EbookHero'
import { PageDataNonFictional } from '@/Const/nonfictional'
import { Helmet } from 'react-helmet'
import Benefits from '@/Components/Service/Benefits'
const ClientData = [
  {
    text: '“Estorytellers are well known for Ghostwriting. I thought to give them a shot. Overall, worth the hype. Keep it up! - Michael Schäffer ”',
    color: '#FFFBEE',
  },
  {
    text: '“The best Ghostwriting services in India. I called them to write a book for me. They not only supported me for writing, but also helped me with publishing. I am really satisfied with their services! - Uma Swaminathan\n”',
    color: '#FFEBB0',
  },
  {
    text: "“Remarkable ghostwriters at affordable rates, without worrying about any kind of problems with delivery and quality. That's what I would say about my experience with Estorytellers. - Xi Mang Po”",
    color: '#FCE290',
  },
  {
    text: '“I like the honesty of Kritika Kanodia, CEO of Estorytellers. She also explained the whole process with ease. Project is currently, ongoing, and as of now, I am truly satisfied with their writers, delivery, and customer service. - Sakina Nazir\n”',
    color: '#FBDA74',
  },
  {
    text: "“They helped me realize my idea into a beautifully-curated book. I guess, if I hadn't approached you, I wouldn't ever be able to do it myself. Thank you so much! - Harpreet Kaur”",
    color: '#FBDA74',
  },
]
const ServiceEbookPage = () => {
  return (
    <>
      <Helmet>
        <title>Expert Fiction Ghostwriting Services | Estorytellers</title>
        <meta
          name="description"
          content="Turn your story ideas into captivating novels with professional ebook ghostwriting services from Estorytellers. Get a free quote today!"
        />
      </Helmet>
      <Header />
      <EbookHero />
      <ServiceOffer
        data={PageDataEbook.offerList}
        color={PageDataEbook.lightColor}
        title={PageDataEbook.offerListHeading}
      />
      <ServiceInclude
        gridSize={4}
        data={PageDataEbook.includeList}
        color={PageDataEbook.lightColor}
        title={PageDataEbook.includeTitle}
      />
      <Benefits />
      {/*<ServiceWhatWeDo*/}
      {/*  darkColor={PageDataEbook.darkColor}*/}
      {/*  lightColor={PageDataEbook.lightColor}*/}
      {/*  title={PageDataEbook.articleTitle}*/}
      {/*/>*/}
      <ClientsHome
        bgColor={'#fff'}
        Data={ClientData}
        cardColor={PageDataEbook.lightColor}
      />
      <ServiceForm
        darkColor={PageDataEbook.darkColor}
        lightColor={PageDataEbook.lightColor}
      />
      <Faqs
        darkColor={PageDataEbook.darkColor}
        lightColor={PageDataEbook.lightColor}
      />
      <Newsletter lightColor={PageDataEbook.lightColor} />

      <Footer />
    </>
  )
}
export default ServiceEbookPage
