import React, { useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import { gsap, ScrollTrigger } from 'gsap/all'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(ScrollTrigger)

let mm = gsap.matchMedia()

const Packages = () => {
  const wrapperRef = useRef()
  const listRef = useRef()
  const leftCardRef = useRef()
  const rightCardRef = useRef()
  const headerRef = useRef()
  const buttonRef = useRef()

  useGSAP(() => {
    mm.add('(min-width: 800px)', () => {
      let t1 = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          markers: false,
          pin: true, // pin the trigger element while active
          start: 'top +=150', // when the top of the trigger hits the top of the viewport
          end: '+=2500 100%', // end after scrolling 500px beyond the start
          scrub: true,
          pinSpacing: true, // Adjust pin spacing
        },
      })

      t1.fromTo(
        leftCardRef.current,
        {
          x: 350,
          ease: 'power1.easeInOut',
        },
        {
          x: 0,
          ease: 'power1.easeInOut',
        },
      )
        .fromTo(
          rightCardRef.current,
          {
            x: -350,
            ease: 'power1.easeInOut',
          },
          {
            x: 0,
            ease: 'power1.easeInOut',
          },
          '<',
        )
        .fromTo(
          headerRef.current,
          {
            y: 0,
            opacity: 1,
            scale: 1,
            ease: 'power1.easeInOut',
          },
          {
            y: -50,
            opacity: 0,
            scale: 0.9,
            ease: 'power1.easeInOut',
          },
        )
        .fromTo(
          buttonRef.current,
          {
            y: 0,
            opacity: 1,
            scale: 1,
            ease: 'power1.easeInOut',
          },
          {
            y: -50,
            opacity: 0,
            scale: 0.9,
            ease: 'power1.easeInOut',
          },
          '<',
        )
    })
  })

  return (
    <>
      <div
        className={styles.wrapper}
        ref={wrapperRef}
      >
        <h5 ref={headerRef}>Which package is right for you?</h5>

        <div className={styles.packageCardList} ref={listRef}>
          <div className={styles.packageCard} ref={leftCardRef}>
            <div className={styles.topPart}>
              <img
                src="/images/package_one.svg"
                alt=""
                className={styles.packageImg}
              />
              <div className={styles.title}>Starter</div>
              <div className={styles.price}>
                <sup>₹ </sup> 40,000
              </div>
              <div className={styles.subTitle}>plan starts from</div>
              <div className={styles.cta}>Contact us</div>
              <div className={styles.dividerContainer}>
                <img
                  src="/images/stroke_divider.svg"
                  alt=""
                  className={styles.divider}
                />
              </div>
            </div>
            <div className={styles.bottomPart}>
              <ul className={styles.benefitList}>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  100 to 120 pages
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Pre-planning and research Strategy sessions with Experts
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Professional editing & book development by Senior Editor
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Comprehensive book-writing by professional writer
                </li>{' '}
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Custom publishing navigation to land the best home for your
                  book{' '}
                </li>
              </ul>
            </div>
          </div>

          {/* Second */}
          <div className={`${styles.packageCard} ${styles.two}`}>
            <img src="/images/badge.svg" alt="" className={styles.badge} />
            <div className={styles.topPart}>
              <img
                src="/images/package_two.svg"
                alt=""
                className={styles.packageImg}
              />
              <div className={styles.title}>Writer Pro</div>
              <div className={styles.price}>
                <sup>₹ </sup> 60,000
              </div>
              <div className={styles.subTitle}>plan starts from</div>
              <div className={styles.cta}>Contact us</div>
              <div className={styles.dividerContainer}>
                <img
                  src="/images/stroke_divider.svg"
                  alt=""
                  className={styles.divider}
                />
              </div>
            </div>
            <div className={styles.bottomPart}>
              <ul className={styles.benefitList}>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  50 to 170 pages
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Pre-planning and research Strategy sessions with Experts
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Professional editing & book development by Senior Editor
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Comprehensive book-writing by professional writer
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Custom publishing navigation to land the best home for your
                  book
                </li>
              </ul>
            </div>
          </div>

          {/*Third*/}
          <div
            className={`${styles.packageCard} ${styles.three}`}
            ref={rightCardRef}
          >
            <div className={styles.topPart}>
              <img
                src="/images/package_three.svg"
                alt=""
                className={styles.packageImg}
              />
              <div className={styles.title}>Writer Plus</div>
              <div className={styles.price}>
                <sup>₹ </sup> 80,000
              </div>
              <div className={styles.subTitle}>plan starts from</div>
              <div className={styles.cta}>Contact us</div>
              <div className={styles.dividerContainer}>
                <img
                  src="/images/stroke_divider.svg"
                  alt=""
                  className={styles.divider}
                />
              </div>
            </div>
            <div className={styles.bottomPart}>
              <ul className={styles.benefitList}>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  200 to 220 pages
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Pre-planning and research Strategy sessions with Experts
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Professional editing & book development by Senior Editor
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Comprehensive book-writing by professional writer
                </li>
                <li>
                  <img src="/images/check-verified.svg" alt="" />
                  Custom publishing navigation to land the best home for your
                  book
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*<div className={styles.sale}>*/}
      {/*  All the things you need to get started.*/}
      {/*  <Link to={'/'}> Jump to plan details</Link>*/}
      {/*</div>*/}
    </>
  )
}
export default Packages
